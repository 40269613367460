import React from 'react'
import ProductTemplate from 'components/templates/ProductTemplate/ProductTemplate'

export default function GeneralPartWorkerTrainingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const products = {
    0: {
      productId: 'GPWT',
      productName: 'Formazione lavoratore parte generale',
      productImages: {
        0: ['courses/corso-formazione-lavoratore-parte-generale.jpg', 'corso formazione parte generale'],
      },
      productSize: 'Un corso formazione',
      productWeight: 0,
      productPrice: '4000',
      price: 'price_1J0nxSLde8wm4I0okoxdp5P6',
    },
  }

  const description =
    '<strong>OBBLIGATORIETÀ:</strong> Obbligatorio per tutti i lavoratori di qualsiasi tipologia di azienda<br/><br/> <strong>DURATA:</strong> 4 h<br/>'

  const presentation = {
    title: 'Formazione lavoratore parte generale',
    description:
      '<strong>COME SI SVOLGE:</strong> ll corso si svolge online in modalità e-learning. All’acquisto vengono inviati link e credenziali con i quali collegarsi in qualsiasi momento, anche in più volte, dal proprio PC o dallo smart phone. Alla fine del corso è previsto un test finale che può essere sostenuto più volte, superato il quale viene rilasciato regolare attestato di avvenuta formazione, accreditato da Regione, in conformità al D. Lgs. 81/08 ed all’Accordo Stato Regioni del 21 dicembre 2011, che definisce durata e contenuti minimi del corso.<br/><br/> <strong>DESCRIZIONE:</strong> Come previsto dall’Accordo Stato Regioni del 21.12.2011, il corso fornisce ai partecipanti gli approfondimenti e aggiornamenti necessari per conoscere i principi del Sistema di Prevenzione e Protezione adottato presso le Aziende e attraverso quali strumenti e strategie il lavoratore è chiamato ad operare per preservare lo stato di sicurezza sul luogo di lavoro.',
    photo: 'corso-formazione-generale-diagramma.jpg',
  }

  return (
    <>
      <ProductTemplate
        seoKeywords={seoKeywords}
        products={products}
        description={description}
        presentation={presentation}
      />
    </>
  )
}
